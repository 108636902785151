
import { defineComponent, Ref, ref, computed, reactive, onMounted, watchEffect } from 'vue'
import { store } from "@/store";
import { FormInstance } from 'element-plus';
import Customers from '@/core/services/Callable/Customers';
import Validations from '@/core/services/etc/Validations';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from '@/router/clean';
import { etc, rule, notification, registration, constant, common, informationModal } from '@/store/stateless';
import Invoice from '@/core/services/Callable/Invoice';
import Etcs from '@/core/services/Callable/Etcs';
import { InvoiceMutationTypes } from '@/store/enums/Invoice/Mutations';

export default defineComponent({
    setup() {

        const currency = registration.currency;

        const ruleFormRef = ref<FormInstance>()

        const health = computed(() => {
            return store.state.customer.health;
        })

        const loading = computed(() => {
            return store.state.customer.detailLoading
        })

        const clickLoading = computed(() => {
            return store.state.invoice.loading
        })

        const invoice = computed(() => {
            return store.state.invoice.groupInvoice;
        })

        const openModal = computed(() => {
            return store.state.invoice.openInvoiceModal;
        });

        const isError = ref<boolean>(false);

        const mainInvoiceDetails = computed(() => {
            return store.state.invoice.groupInvoice;
        });

        const closeModal = (e) => {
            store.commit(InvoiceMutationTypes.SET_INVOICE_MODAL, false);
        };
        

        const rules = reactive({
            // insurance_type: [{ validator: Validations.insurance_type, trigger: ['blur'] }],
            insurance_plan: [{ validator: Validations.insurance_plan, trigger: ['blur', 'change'] }],
            policy_price: [{ validator: Validations.policy_price, trigger: ['blur', 'change'] }],
            invoice_amount: [{ validator: Validations.invoice_amount, trigger: ['blur', 'change'] }],
            sales_price: [{ validator: Validations.sales_price, trigger: ['blur', 'change'] }],
            client_classification: [{ validator: Validations.client_classification, trigger: ['blur', 'change'] }],
            discount_amount: [{ validator: Validations.discount_amount, trigger: ['blur', 'change'] }],
            payment_type: [{ validator: Validations.payment_type, trigger: ['blur', 'change'] }],
            pending_payment_type: [{ validator: Validations.payment_type, trigger: ['blur', 'change'] }],
            // order_description: [{ validator: Validations.order_description, trigger: ['blur', 'change'] }],
            reference_no: [{ validator: Validations.reference_no, trigger: ['blur', 'change'] }],
            payment_date: [{ validator: Validations.payment_date, trigger: ['blur', 'change'] }],
            installments: [{ validator: Validations.installments, trigger: ['blur', 'change'] }],
            file_receipt: [{ validator: Validations.file_receipt, trigger: ['blur', 'change'] }],
            expiry_date_time: [{ validator: Validations.expiry_date_time, trigger: ['blur', 'change'] }],
        })

        let Receipt: any;
        let chequeReceipt: any;

        const handleFileUpload = (file) => {
            Receipt = file;
        };

        const handleChequeFileUpload = (file) => {
            chequeReceipt = file;
        }

        const revalidate = () => {
            if(invoice.value.payment_type == '3'){
                ruleFormRef.value?.clearValidate('order_description')
            }
            if (isSubmitted.value) {
                ruleFormRef.value?.validate((valid) => {
                    if (!valid) {
                        // 
                    }
                })
            }
            
        }

        const validateGroupDetails = () => {
            const lookup2 = invoice.value.categories.filter(x => x.name != null && x.visa_emirates != null);
            // console.log(lookup2)

            if(lookup2.length == 0)
                return

            const valid = checkDuplicateCategory();
            if(!valid) {
                informationModal.value.open = true
                informationModal.value.title = 'Duplicate Group Details'
                informationModal.value.message = 'Category and Visa Issued Emirate is duplicated in the group details section. Kindly update the group details information to proceed.'
            }
            else {
                notification.success(
                            'Group Details',
                            'No Duplicates found'
                        );
            }

            return valid;
        }

        const checkDuplicateCategory = () => {
            console.log('check duplicate')
            const lookup = health.value.categories
            const lookup2 = invoice.value.categories
            let isValid = true
            const oldValue = <any>[]

            for(const i of lookup) {
                if(lookup2.length > 0) {
                    let checker = lookup2.filter(x => x.name == i.name && x.visa_emirates == i.visa_emirates)
                    if(checker.length > 0) isValid = false
                }
            }

            for(const j of lookup2) {
                if(oldValue.length > 0) {
                    let checker = oldValue.filter(x => x.name == j.name && x.visa_emirates == j.visa_emirates)
                    if(checker.length > 0) isValid = false
                }

                oldValue.push(j)
            }

            return isValid
        }

        const isSubmitted = ref(false)
        const top = ref()
        const middle = ref()
        const last = ref()
        const invoiceRef = ref()
        const groupInfoRef = ref()
        const amendInvoice = async (formEl: FormInstance | undefined) => {
            Validations.is_reference_no_exist = false
            

            // console.log(policyData.value)
            // invoice.value.members = health.value.members

            isSubmitted.value = true
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                // console.log(valid,fields)
                
                if (valid) {
                    if(!validateGroupDetails())
                        return

                    const formData = new FormData();

                    for (const key in invoice.value) {
                        formData.set(key, invoice.value[key]?.toString() ?? '');
                    }
                    

                    const lead_id = router.currentRoute.value.params.lead_id.toString();
                    formData.set('categories', JSON.stringify(invoice.value.categories))
                    formData.set('customer_id', health.value.id ? health.value.id.toString() : '')
                    formData.set('policy_sales_agent_id', health.value.agent?.toString() ?? '0')
                    formData.set('lead_id', lead_id)
                    formData.set('invoice_doc', Receipt ?? '');
                   

                    const result = await Invoice.GroupCategoryAdditionInvoice(formData);
                    // console.log(result); 
                    if (result.status == 200) {


                        notification.success(
                            'Invoice',
                            'Invoice made successfully'
                        );
                        // goLists(health.value.id);

                        // window.open(result.data.data.file_path, '_blank');
                    } else {
                        if(result.response?.data?.data?.reference_no) {
                            
                            Validations.is_reference_no_exist = true
                            rule.customerDetails = 'reference_no'
                            setTimeout(() => {
                                formEl.validateField('reference_no')
                                invoiceRef.value.focus()
                            }, 200);
                        }
                    }
                }
                else {
                    rule.customerDetails = Object.keys(fields)[0]
                    const cat = Object.keys(fields).filter(x => x.toLowerCase().indexOf('categories') !== -1)
                    if(cat.length > 0) {
                        rule.customerDetails = cat[0]
                        groupInfoRef.value.focus()
                    } else {
                     if(invoiceRef.value) invoiceRef.value.focus();
                    }
                }
            })
        }


        const invoiceDetails:Ref<any> = ref(null) ;

        onMounted(async () => {
            rule.customerDetails = 'categories.0.name'

            setCurrentPageBreadcrumbs("Category Addition ", [{ label: "Invoices", value: "invoiceApproval" }]);

            await Etcs.getVat();
            await Etcs.getBasmah({insured_city:constant.dubai_emirate , calculate_amount : true});

            await Etcs.getEmployeeCategories();
            await Etcs.getInsurancePolicies({isDropdown: true, isGroup:true });

            await Customers.getLeadsByLeadId({
                id: parseInt(router.currentRoute.value.params.lead_id.toString())
            }).then(() => {
                window.scrollTo(0, 300);
            })

            invoiceDetails.value = await Invoice.getGroupInvoice({
                invoice_id: parseInt(router.currentRoute.value.params.invoice_id.toString())
            })

            invoiceDetails.value.categories = []

            console.log('invoiceDetails', invoiceDetails.value);

            invoice.value.invoice_id = invoiceDetails.value?.id
            invoice.value.lead_id = invoiceDetails.value?.lead_id
            invoice.value.client_classification = invoiceDetails.value?.client_classification
            invoice.value.quote_ref_no = invoiceDetails.value?.merchant_reference
            invoice.value.all_invoices = invoiceDetails.value?.all_invoices
            // Invoice.setGroupInvoice(invoiceDetails.value)

            await addNewCategory()
            setTimeout(() => {
                groupInfoRef.value.focus()
            }, 1000);
        })

        const goLists = (customer_id: any) => {
            router.replace({ name: 'CustomerDetails', params: { customer_id } })
        }

        const total_counts = (obj, target) => {
            return obj.reduce((item, data) => {
                        if(data.amend_type != 2) {
                            item += Number(data[target])
                        } else {
                            item -= Number(data[target])
                        }
                        return item
                    },0)
        }



        const checkform = () => {
            const myForm: any = document.querySelectorAll('#last')
            console.log(myForm)
        }

        const invoiceDetailsModal = async () => {
            const data = {
                show: true,
                invoice: invoiceDetails.value
            }
            // console.log('invoice',invoiceDetails?.categories);
            await Invoice.setInvoiceDetailsModal(data);
        }

        const invoiceLogModal = async () => {
            await Invoice.getGroupInvoiceLog({lead_id: invoiceDetails.value.lead_id, invoice_id: invoiceDetails.value.id});
        }

        const addNewCategory = () => {
            if(!ruleFormRef.value) return

            const name = health.value.categories.map((x, i) => {
                return [`categories.${i}.name`, `categories.${i}.visa_emirates`, `categories.${i}.insurance_plan_id`, `categories.${i}.group_count`, `categories.${i}.amount`]
            }).flat()

            if(invoice.value?.categories.length > 0)  
                ruleFormRef.value.validateField(name)
                .then((valid) => {
                    if(valid) {
                    invoice.value?.categories?.push({
                        id: null,
                        name: null,
                        insurance_provider_id: null,
                        insurance_providers: [],
                        insurance_plan_id: null,
                        insurance_policies: [],
                        group_count: 1,
                        members_above_60: 0,
                        visa_emirates: null,
                        
                        visa_emirates_text: null,
                        category_name: null,
                        insurance_plan: null,
                        amount:0,
                        non_taxable_fee: 0,
                        icp:0,
                        basmah:0,
                        icp_disabled:false
                    })
                }
            })
            .catch((error) => {
                rule.customerDetails = Object.keys(error)[0]
                setTimeout(() => {
                    groupInfoRef.value.focus()
                }, 100);
            })

            if(invoice.value.categories.length == 0) {
                invoice.value?.categories?.push({
                        id: null,
                        name: null,
                        insurance_provider_id: null,
                        insurance_providers: [],
                        insurance_plan_id: null,
                        insurance_policies: [],
                        group_count: 1,
                        members_above_60: 0,
                        visa_emirates: null,
                        
                        visa_emirates_text: null,
                        category_name: null,
                        insurance_plan: null,
                        amount:0,
                        non_taxable_fee: 0,
                        icp:0,
                        basmah:0,
                        icp_disabled:false
                    })
            }
        }

        return {
            validateGroupDetails,
            common,
            store,
            clickLoading,
            checkform,
            top,
            middle,
            last,
            revalidate,
            loading,
            rule,
            rules,
            health,
            ruleFormRef,
            etc,
            goLists,
            amendInvoice,
            handleFileUpload,
            handleChequeFileUpload,
            invoice,
            openModal,
            isError,
            closeModal,
            invoiceRef,
            groupInfoRef,
            invoiceDetailsModal,
            invoiceDetails,
            currency,
            mainInvoiceDetails,
            invoiceLogModal,
            addNewCategory
        }
    },
})
